import IncidencesConstants from "../constants/services/incidences";
import IncidencesDestination from "../constants/services/incidences-destination";
import IncidencesReturn from "../constants/services/incidences-return";
import IncidencesClient from "../constants/services/incidences-client";
import IncidencesDriver from "../constants/services/incidences-driver";
import IncidencesService from "../constants/services/incidences-service";
import IncidencesCollection from "../constants/services/incidences-collection";

const incidenceCategory = (categoryId, incidenceId, isAdmin) => {
  switch (categoryId) {
    case IncidencesConstants.NUM_INCIDENCE_DESTINATION:
      return isAdmin ? IncidencesDestination.get[incidenceId] : null;
    case IncidencesConstants.NUM_INCIDENCE_CLIENT:
      return IncidencesClient.get[incidenceId];
    case IncidencesConstants.NUM_INCIDENCE_RETURN:
      return IncidencesReturn.get[incidenceId];
    case IncidencesConstants.NUM_INCIDENCE_RETURN_BY_DRIVER:
      return IncidencesDriver.get[incidenceId];
    case IncidencesConstants.NUM_INCIDENCE_SERVICES:
      return IncidencesService.get[incidenceId];
    case IncidencesConstants.NUM_INCIDENCE_COLLECTION:
      return IncidencesCollection.get[incidenceId];
    default:
      return isAdmin ? IncidencesDestination.get[incidenceId] : null;
  }
};

export const incidenceMessage = (categoryId, incidenceId, isAdmin = true) => {
  return incidenceCategory(incidenceId, categoryId, isAdmin);
};
