<template>
  <div>
    <div v-if="showModal">
      <z-modal
        :id="modalId"
        :show="true"
        :centered="centered"
        :body-class="`p-0 rounded ${bodyClass}`"
        :hide-close="hideClose"
        :size="size"
        :no-close-on-backdrop="creating"
        :no-close-on-esc="creating"
        @hide="$emit('hide')"
      >
        <slot />
      </z-modal>
    </div>
    <b-card v-else class="mobile-view" :body-class="`p-0 ${bodyClass}`">
      <slot />
    </b-card>
  </div>
</template>

<script>
import ScreensMixin from "@/mixins/screen";
import ZModal from "./ZModal";

export default {
  name: "ZResponsiveModal",

  components: {
    ZModal
  },

  mixins: [ScreensMixin],

  props: {
    modalId: {
      type: String,
      default: ""
    },
    bodyClass: {
      type: String,
      default: ""
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    },
    creating: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showModal: false
    };
  },

  created() {
    if (this.$route.name !== "serviceDetail") {
      this.showModal = this.isDesktop();
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-view {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  border: 0px;
  margin-bottom: 52px;
}
</style>
