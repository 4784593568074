import WindowBreakpoints from "@zubut/common/src/constants/window/breakpoints";

export default {
  computed: {
    windowWith() {
      return this.$store.state.windowWidth;
    },
    isLargeScreen() {
      return this.windowWith >= WindowBreakpoints.SIZE_LARGE;
    }
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDesktop() {
      if (this.windowWith >= WindowBreakpoints.SIZE_LARGE) {
        return true;
      } else {
        return false;
      }
    }
  }
};
