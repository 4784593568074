const NUM_CLIENT_NOT_LOCATED = 1;
const NUM_ADDRESS_NOT_LOCATED = 2;
const NUM_PARCEL_NOT_MATCH = 3;
const NUM_PARCEL_NOT_AVAIABLE = 4;

const CLIENT_NOT_LOCATED = "Cliente no localizado";
const ADDRESS_NOT_LOCATED = "Domicilio no localizado";
const PARCEL_NOT_MATCH = "Paquete no coincide en dimensiones";
const PARCEL_NOT_AVAIABLE = "Paquete no disponible";

const get = {};
get[NUM_CLIENT_NOT_LOCATED] = CLIENT_NOT_LOCATED;
get[NUM_ADDRESS_NOT_LOCATED] = ADDRESS_NOT_LOCATED;
get[NUM_PARCEL_NOT_MATCH] = PARCEL_NOT_MATCH;
get[NUM_PARCEL_NOT_AVAIABLE] = PARCEL_NOT_AVAIABLE;

export default {
  get
};
