const NUM_INCIDENCE_DESTINATION = 1;
const NUM_INCIDENCE_CLIENT = 2;
const NUM_INCIDENCE_SERVICES = 3;
const NUM_INCIDENCE_RETURN = 4;
const NUM_INCIDENCE_RETURN_BY_DRIVER = 5;
const NUM_INCIDENCE_COLLECTION = 6;

const INCIDENCE_DESTINATION = "Incidencia";
const INCIDENCE_CLIENT = "Incidencias con el cliente";
const INCIDENCE_SERVICES = "Incidencias en el pedido";
const INCIDENCE_RETURN = "Intento de entrega";
const INCIDENCE_RETURN_BY_DRIVER = "Regreso de paquete";
const INCIDENCE_COLLECTION = "Intento de recolección";

const get = {};
get[NUM_INCIDENCE_DESTINATION] = INCIDENCE_DESTINATION;
get[NUM_INCIDENCE_CLIENT] = INCIDENCE_CLIENT;
get[NUM_INCIDENCE_SERVICES] = INCIDENCE_SERVICES;
get[NUM_INCIDENCE_RETURN] = INCIDENCE_RETURN;
get[NUM_INCIDENCE_RETURN_BY_DRIVER] = INCIDENCE_RETURN_BY_DRIVER;
get[NUM_INCIDENCE_COLLECTION] = INCIDENCE_COLLECTION;

export default {
  get,
  NUM_INCIDENCE_DESTINATION,
  NUM_INCIDENCE_CLIENT,
  NUM_INCIDENCE_SERVICES,
  NUM_INCIDENCE_RETURN,
  NUM_INCIDENCE_RETURN_BY_DRIVER,
  NUM_INCIDENCE_COLLECTION
};
