const SIZE_SMALL = 576;
const SIZE_MEDIUM = 768;
const SIZE_LARGE = 992;
const SIZE_XLARGE = 1200;
const SIZE_XXLARGE = 1400;

export default {
  SIZE_SMALL,
  SIZE_MEDIUM,
  SIZE_LARGE,
  SIZE_XLARGE,
  SIZE_XXLARGE
};
