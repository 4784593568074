<template>
  <div
    class="d-flex flex-row align-items-center justify-content-center no-select"
    data-test-id="content"
    :class="[size, { active }]"
    :style="{ backgroundColor: backgroundColor }"
  >
    {{ displayContent }}
  </div>
</template>

<script>
import ServiceType from "../constants/services/type";

export default {
  name: "ServiceTypeAvatar",

  props: {
    type: {
      type: Number,
      required: false,
      default: null
    },
    content: {
      type: String,
      required: false,
      validator: value => value.length > 0 && value.length <= 2,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      default: "md",
      validator: value => ["md", "lg", "xl"].indexOf(value) !== -1
    },
    active: {
      type: Boolean,
      default: null
    }
  },

  data() {
    return {
      defaultColor: "red",
      defaultContent: "S",
      colorTypeMatch: {
        [ServiceType.NUM_SIMPLE]: "#ED6C5A",
        [ServiceType.NUM_MULTI_POINT]: "#ED6C5A",
        [ServiceType.NUM_RENT_PER_HOUR]: "#0036A1",
        [ServiceType.NUM_DYNAMIC]: "#ED6C5A",
        [ServiceType.NUM_PACKAGE_DELIVERY]: "#333333",
        [ServiceType.NUM_BATCH]: "#E10098",
        [ServiceType.NUM_PARCEL_DELIVERY]: "#6655F6",
        [ServiceType.NUM_PARCEL_PICKUP]: "#6655f6"
      },
      contentTypeMatch: {
        [ServiceType.NUM_SIMPLE]: "E",
        [ServiceType.NUM_MULTI_POINT]: "E",
        [ServiceType.NUM_RENT_PER_HOUR]: "D",
        [ServiceType.NUM_DYNAMIC]: "E",
        [ServiceType.NUM_PACKAGE_DELIVERY]: "R",
        [ServiceType.NUM_BATCH]: "L",
        [ServiceType.NUM_PARCEL_DELIVERY]: "G",
        [ServiceType.NUM_PARCEL_PICKUP]: "G"
      }
    };
  },

  computed: {
    displayContent() {
      if (this.content != null) return this.content;
      else if (this.type != null) return this.contentTypeMatch[this.type];
      return this.defaultContent;
    },
    backgroundColor() {
      if (this.color != null) return this.color;
      if (this.type != null) return this.colorTypeMatch[this.type];
      return this.defaultColor;
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  background-color: red;
  border-radius: 6px;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.xl {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

.lg {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}

.md {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
}

.active {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -5px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    border: 1px solid $white;
    background: $zubut-blue;
  }
}
</style>
