<template>
  <div class="mt-5 mb-5 d-flex">
    <div class="address-image">
      <z-icon :name="addressIcon" />
    </div>
    <div class="address-card-details">
      <p class="font-weight-bold text-lg mb-2">{{ address.name }}</p>
      <p class="m-0">{{ address.address }}</p>
      <p class="m-0">{{ address.phone }}</p>
      <p v-if="address.information" class="m-0">{{ address.information }}</p>
    </div>
  </div>
</template>

<script>
import ZIcon from "@zubut/common/src/components/ZIcon";

export default {
  name: "AddressCard",

  components: {
    ZIcon
  },

  props: {
    address: {
      type: Object,
      default: () => {
        return {
          name: "",
          address: "",
          phone: ""
        };
      }
    },
    addressType: {
      type: String,
      default: "reservation"
    }
  },

  computed: {
    addressIcon() {
      switch (this.addressType) {
        case "origin":
          return "Restaurant";
        case "destination":
          return "House";
        case "reservation":
          return "Bussiness";
        default:
          return "Bussiness";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.address-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  background-color: $solitude;
  border-radius: 0.6em;
}

.address-card-details {
  max-width: 80%;
}
</style>
